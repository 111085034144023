import axios from "axios";
import store from "@/common/store";
import moment from "moment";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "api/v1/",
  timeout: 15000,
  headers: {
    Authorization: ""
  }
});

// Add a request interceptor
instance.interceptors.request.use(
  async function(config) {
    if (
      store.getters["login/tokenAlmostExpired"]() &&
      config.url !== "oauth/token"
    ) {
      await store.dispatch("login/refreshToken");
    }
    if (store.getters["login/getAccessToken"]) {
      config.headers["Authorization"] =
        "Bearer " + store.getters["login/getAccessToken"];
    }

    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    // Do something with response data
    if (response.data) {
      if (response.data.refresh_token) {
        store.dispatch("login/setRefreshToken", {
          refreshToken: response.data.refresh_token
        });
      }
      if (response.data.access_token) {
        store.dispatch("login/setAccessToken", {
          accessToken: response.data.access_token
        });
      }
      if (response.data.expires_in) {
        //refreshTokenExpiresIn: moment().unix() + response.data.expires_in
        store.dispatch("login/setRefreshTokenExpiresIn", {
          refreshTokenExpiresIn: moment().unix() + response.data.expires_in
        });
      }
    }

    return response;
  },
  error => {
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 401)
    ) {
      if (error.response.data.message == "Unauthenticated.") {
        store.dispatch("login/logOff");
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
