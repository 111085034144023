import Vue from "vue";

import "./element-variables.scss";

import {
  Select,
  Dialog,
  Loading,
  MessageBox,
  Message,
  Button,
  Switch,
  Input,
  Autocomplete,
  Option
} from "element-ui";

import lang from "element-ui/lib/locale/lang/nl";
import locale from "element-ui/lib/locale";

Select.computed.readonly = function() {
  // trade-off for IE input readonly problem: https://github.com/ElemeFE/element/issues/10403
  const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));
  const isEdge = !this.$isServer && navigator.userAgent.indexOf("Edge") > -1;

  if (this.filterable) return false;

  return this.multiple || (!isIE && !isEdge && !this.visible);
};

// configure language
locale.use(lang);

Vue.use(Dialog);
Vue.use(Select);
Vue.use(Button);
Vue.use(Switch);
Vue.use(Input);
Vue.use(Autocomplete);
Vue.use(Option);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
