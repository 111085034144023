import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
      name: "Start",
      meta: {
        login: true
      }
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/common/views/login/Login.vue"),
      meta: {
        login: true
      }
    },
    {
      path: "/choose-tenant",
      name: "ChooseTenant",
      component: () => import("../views/tenants/ChooseTenant.vue")
    },
    {
      path: "",
      props: true,
      component: () => import("@/common/views/ViewAbstract.vue"),
      children: [
        {
          path: "/dashboard",
          name: "Dashboard",
          component: () => import("@/common/views/dashboard/Dashboard.vue"),
          meta: {
            permissions: ["CAN_SUBSTITUTE", "CAN_VIEW_ABSENCES"]
          }
        },
        {
          path: "/settings",
          name: "Settings",
          component: () => import("../views/settings/Settings.vue"),
          meta: {
            permissions: ["CAN_SUBSTITUTE", "CAN_VIEW_ABSENCES"]
          }
        }
      ]
    }
  ]
});
