export default {
  routes: [
    {
      path: "",
      props: true,
      component: () => import("@/common/views/ViewAbstract.vue"),
      children: [
        {
          path: "/requests/:substitutionIdRequest",
          name: "Requests",
          props: true,
          component: () =>
            import("@/modules/replacer/views/requests/Requests.vue"),
          meta: {
            permissions: ["CAN_SUBSTITUTE"]
          }
        },
        {
          path: "/availability",
          name: "Availability",
          component: () =>
            import("@/modules/replacer/views/availability/Availability.vue"),
          meta: {
            permissions: ["CAN_SUBSTITUTE"]
          }
        },
        {
          path: "/replacement/:id",
          name: "Replacement",
          props: true,
          component: () => import("../views/replacement/Replacement.vue"),
          meta: {
            permissions: ["CAN_SUBSTITUTE"],
            hideFooter: true
          }
        }
      ]
    }
  ]
};
