import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import router from "@/common/router";
import { LoginState } from "./types";
import { RootState } from "../types";
import axios from "axios";
import { AxiosResponse, AxiosError } from "axios";
import moment from "moment";

export const state: LoginState = {
  accessToken: "",
  refreshToken: "",
  refreshTokenExpiresIn: 0,
  isLoggedIn: false,
  lastRoute: null,
  loginAttempts: 0,
  logOffOnPause: true,
  lastActiveTime: 0
};

const actions: ActionTree<LoginState, RootState> = {
  async setLoggedIn({ commit, dispatch, state }): Promise<void> {
    commit("resetLock");
    commit("setLoggedIn");

    await dispatch("user/getUserProfile", {}, { root: true });

    if (state.lastRoute !== null) {
      router.push(state.lastRoute);
    } else {
      router.push({ name: "ChooseTenant" });
    }
  },
  setLastActiveTime({ commit }) {
    const lastActiveTime = Math.floor(Date.now() / 1000);
    commit("setLastActiveTime", lastActiveTime);
  },
  logOff({ commit }): void {
    commit("setLoggedOff");
    if (router.currentRoute.name !== "Login") {
      router.push({ name: "Login" });
    }
  },
  async refreshToken({ dispatch, state }): Promise<void> {
    const response: void | AxiosResponse = await axios({
      baseURL: "https://vvbeta.nl/",
      url: "oauth/token",
      method: "post",
      data: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        grant_type: "refresh_token",
        // eslint-disable-next-line @typescript-eslint/camelcase
        refresh_token: state.refreshToken,
        // eslint-disable-next-line @typescript-eslint/camelcase
        client_id: process.env.VUE_APP_CLIENT_ID,
        // eslint-disable-next-line @typescript-eslint/camelcase
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        scope: ""
      }
    }).catch((error: AxiosError) => {
      console.log(error);
    });

    if (response) {
      if (response.data.refresh_token) {
        dispatch("setRefreshToken", {
          refreshToken: response.data.refresh_token
        });
      }
      if (response.data.access_token) {
        dispatch("setAccessToken", {
          accessToken: response.data.access_token
        });
      }
      if (response.data.expires_in) {
        dispatch("setRefreshTokenExpiresIn", {
          refreshTokenExpiresIn: moment().unix() + response.data.expires_in
        });
      }
    }
  },
  setRefreshToken({ commit }, data): void {
    commit("setRefreshToken", data);
  },
  setRefreshTokenExpiresIn({ commit }, data): void {
    commit("setRefreshTokenExpiresIn", data);
  },
  setAccessToken({ commit }, data): void {
    commit("setAccessToken", data);
  },
  unsetAccessToken({ commit }): void {
    commit("unsetAccessToken");
  }
};

const mutations: MutationTree<LoginState> = {
  setLoggedIn(state) {
    state.isLoggedIn = true;
  },
  resetLock(state) {
    state.loginAttempts = 0;
  },
  setLoggedOff(state) {
    state.isLoggedIn = false;
  },
  setLastActiveTime(state, time: number) {
    state.lastActiveTime = time;
  },
  setRefreshToken(state, payload) {
    state.refreshToken = payload.refreshToken;
  },
  setRefreshTokenExpiresIn(state, payload) {
    state.refreshTokenExpiresIn = payload.refreshTokenExpiresIn;
  },
  setAccessToken(state, payload) {
    state.accessToken = payload.accessToken;
  },
  unsetAccessToken(state) {
    state.accessToken = "";
  }
};

const getters: GetterTree<LoginState, RootState> = {
  getAccessToken(state) {
    return state.accessToken;
  },
  tokenAlmostExpired: state => () => {
    return moment().unix() + 60 > state.refreshTokenExpiresIn;
  }
};

const namespaced = true;
export const login: Module<LoginState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
