import { ActionTree, MutationTree, GetterTree } from "vuex";
import { DirectorState } from "./types";
import { RootState } from "@/common/store/types";

export const state: DirectorState = {};

const actions: ActionTree<DirectorState, RootState> = {};

const mutations: MutationTree<DirectorState> = {};

const getters: GetterTree<DirectorState, RootState> = {};

const namespaced = true;
export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
