/* eslint-disable @typescript-eslint/ban-ts-ignore */
import Vue from "vue";
import App from "./App.vue";
import router from "@/common/router";
import store from "@/common/store";
import AuthGuard from "@/common/router/guard";
import Vue2TouchEvents from "vue2-touch-events";

//@ts-ignore
import VueModular from "vue-modular";

// partial import element-ui
import "@/libs/element";

import "@/libs/moment";

import director from "./modules/director";
import replacer from "./modules/replacer";

import "@/assets/sass/libraries/import_bootstrap.scss";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import "@/assets/sass/global.scss";

Vue.use(Vue2TouchEvents);

Vue.use(VueModular, {
  modules: { director, replacer },
  store,
  router
});

Vue.use(AuthGuard, { router, store });

new Vue({
  router,
  store,
  render: h => h(App),
  methods: {
    init() {
      this.$store.dispatch("login/resetLastRoute");

      //@ts-ignore
      document.addEventListener("pause", () => this.pause(), false);
      //@ts-ignore
      document.addEventListener("resume", () => this.resume(), false);

      //@ts-ignore
      EventBus.$emit("device-ready");
    },
    pause() {
      this.$store.dispatch("login/setLastRoute", false);

      if (this.$store.state.login.logOffOnPause) {
        //this.$store.dispatch("login/logOff");
      } else {
        this.$store.dispatch("login/setLogOffOnPause", true);
      }
    },
    resume() {
      if (
        this.$store.state.login.lastActiveTime + 300 <
        Math.floor(Date.now() / 1000)
      ) {
        this.$store.dispatch("login/logOff");
      }
      //@ts-ignore
      EventBus.$emit("device-ready");
    }
  }
}).$mount("#app");
