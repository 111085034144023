export default {
  routes: [
    {
      path: "",
      props: true,
      component: () => import("@/common/views/ViewAbstract.vue"),
      children: [
        {
          path: "/new-request",
          name: "NewRequest",
          component: () => import("../views/requests/NewRequest.vue"),
          meta: {
            permissions: ["CAN_CREATE_ABSENCES"]
          }
        },
        {
          path: "/absence/:id/:date?",
          name: "Absence",
          props: true,
          component: () => import("../views/absence/Absence.vue"),
          meta: {
            permissions: ["CAN_VIEW_ABSENCES"],
            hideFooter: true
          }
        }
      ]
    }
  ]
};
