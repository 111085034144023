import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { UserState } from "./types";
import { RootState } from "../types";
import axios from "@/libs/axios/axios";
import { AxiosResponse, AxiosError } from "axios";
import router from "@/common/router";

export const state: UserState = {
  name: "",
  permissions: [],
  currentTenant: {
    id: "",
    displayName: ""
  }
};

const actions: ActionTree<UserState, RootState> = {
  async setCurrentTenant(
    { commit, dispatch, state },
    { tenant, tenants }
  ): Promise<void> {
    await dispatch("getPermissions", tenant);

    if (state.permissions.length > 0) {
      commit("setCurrentTenant", tenant);

      router.push({ name: "Dashboard" });
    } else if (state.permissions.length <= 0 && tenants.length <= 1) {
      alert("U heeft niet de juiste rechten bij deze client.");
      this.dispatch("login/logOff", {}, { root: true });
    } else {
      alert(
        "U heeft niet de juiste rechten bij deze client, kies een andere client."
      );
    }
  },
  async getPermissions({ commit }, tenant) {
    const response: void | AxiosResponse = await axios("profile/permissions", {
      headers: {
        "X-VVTOOL-TENANT": tenant.id
      }
    }).catch((error: AxiosError) => {
      console.log(error);
    });

    if (response && response.data && response.data.length > 0) {
      commit("setPermissions", response.data);
    }
  },
  async getUserProfile({ dispatch }) {
    const response: void | AxiosResponse = await axios
      .get("profile")
      .catch((error: AxiosError) => {
        console.log(error);
      });

    if (response && response.data) {
      dispatch("setUserName", response.data.name);
    }
  },
  setUserName({ commit }, userName) {
    commit("setUserName", userName);
  }
};

const mutations: MutationTree<UserState> = {
  setCurrentTenant(state, tenant) {
    state.currentTenant = tenant;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
  setUserName(state, userName) {
    state.name = userName;
  }
};

const getters: GetterTree<UserState, RootState> = {
  getCurrentTenant(state) {
    return state.currentTenant;
  },
  getUserName(state) {
    return state.name;
  }
};

const namespaced = true;
export const user: Module<UserState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
