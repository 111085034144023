/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { RootState } from "@/common/store/types";
import { PluginObject, VueConstructor } from "vue";
// @ts-ignore
import { Store } from "vuex";
import _Router from "vue-router";
import { intersection } from "lodash";

class RouteProtect {
  public router: _Router;
  public store: Store<RootState>;

  constructor(Vue: VueConstructor, router: _Router, store: Store<RootState>) {
    this.router = router;
    this.store = store;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public checkRouter(to: any, from: any, next: Function) {
    const loggedIn = this.store.state.login.isLoggedIn;
    const permissions = this.store.state.user.permissions;

    if (!loggedIn && !to.meta.login) {
      return next({ name: "Login" });
    }

    if (
      intersection(permissions, to.meta.permissions).length > 0 ||
      to.meta.login ||
      (loggedIn && to.name === "ChooseTenant")
    ) {
      return next();
    }

    alert("U heeft niet de juiste rechten.");
    this.store.dispatch("login/logOff");
  }
}

export interface AuthOptions {
  router?: _Router;
  store?: Store<RootState>;
}

const install = (
  Vue: VueConstructor,
  options: AuthOptions = {} as AuthOptions
): void => {
  if (!options.router) {
    throw new Error("You must supply a router instance in the options.");
  }
  if (!options.store) {
    throw new Error("You must supply a store instance in the options.");
  }

  const rp = new RouteProtect(Vue, options.router, options.store);

  options.router.beforeEach((to, from, next) => rp.checkRouter(to, from, next));

  return;
};

const plugin: PluginObject<AuthOptions> = {
  install
};
export default plugin;
