import Vue from "vue";
import VuexPersistence from "vuex-persist";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";

import { login, state as loginState } from "./login/index";
import { user, state as userState } from "./user/index";

const debug = process.env.NODE_ENV !== "production";
const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  reducer: state => ({
    login: {
      ...state.login
    },
    user: {
      ...state.user
    }
  })
});

Vue.use(Vuex);

// initial state
const initialState: RootState = {
  login: { ...loginState },
  user: { ...userState }
};

const storeOptions: StoreOptions<RootState> = {
  modules: {
    login,
    user
  },
  actions: {},
  mutations: {
    resetAll(state) {
      Vue.set(state, "login", initialState.login);
    }
  },
  strict: debug
};

export default new Vuex.Store<RootState>({
  ...storeOptions,
  plugins: [vuexLocal.plugin]
});
